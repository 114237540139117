<template>
  <div>
    <div class="bottomBox1" v-if="settingInfo.reportStyle === true">
      <div class="left">
        <!-- logo -->
        <img :src="Info.code" alt="" />
      </div>
      <div class="right">
        <p>{{ Info.name }}</p>
        <p>A: {{ Info.addr }}</p>
        <span></span>
        <p>T: {{ Info.tel }}</p>
        <p>E: {{ Info.email }}</p>
      </div>
    </div>
    <div class="bottomBox2"  v-if="settingInfo.reportStyle === false">
      <div class="right">
        <div class="top">
          <span class="telText"
            >咨 热
            <br />
            询 线</span
          >
          <span class="telephone">{{ Info.tel }}</span>
        </div>

        <span class="name">{{ Info.addr }}</span>
        <span class="name2">{{ Info.englishAddr }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getFooter } from "@/api/setting";
import { getSetting } from "@/api/setting";
export default {
  data() {
    return {
      Info: {
        // name: null,
        addr: null,
        addr: null,
        englishAddr: null
      },
      settingInfo: {}
    };
  },
  methods: {
    async footerInfo() {
      const res = await getFooter();
      console.log(res);
      if (res.code === 200) {
        this.Info = res.data;
      }
    },
        // 获取配置信息
        async getLogs() {
      const res = await getSetting();
      console.log(res);
      if (res.code === 200) {
        this.settingInfo = res.data;
      }
    }
  },
  created() {
    this.footerInfo();
    this.getLogs();
  },
};
</script>

<style lang="scss" scoped>
.bottomBox1 {
  height: 130px;
  width: 100%;
  padding-top: 20px;
  // background: rebeccapurple;
  display: flex;
  // align-items: center;
  border-top: 1px solid #dddddd;
  margin-top: 10px;
  .left {
    width: 70px;
    height: 70px;
    background: #cecece;
    img {
      width: 100%;
    }
  }

  .right {
    margin-left: 20px;
    height: 100px;
    span {
      display: block;
      height: 1px;
      background: #808080;
      margin: 6px 0px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #303030;
      margin: 0px;
      padding: 0;
      // line-height: 17px;
    }
  }
}
.bottomBox2 {
  height: 130px;
  width: 100%;
  padding-top: 20px;
  // background: rebeccapurple;
  display: flex;
  // align-items: center;
  border-top: 1px solid #dddddd;
  margin-top: 10px;
  .left {
    width: 70px;
    height: 70px;
    // background: #CECECE;
    img {
      width: 100%;
    }
  }

  .right {
    // margin-left: 20px;
    height: 100px;
    span {
      display: block;
      font-size: 14px;
    }
    .telText {
      font-size: 12px;
    }
    .top {
      display: flex;
      align-items: center;
      color: var(--custom-color);
      border-bottom: 1px solid #333;
      padding-bottom: 3px;
      margin-bottom: 3px;
      span {
        font-weight: 600;
      }
      .telephone {
        font-size: 20px;
        margin-left: 15px;
      }
    }
    .name {
      font-size: 16px;
    }
    .name2 {
      font-size: 12px;
    }
  }
}
</style>